import './../css/styles.scss';
import { throttle, debounce } from './utils';

function fixBaseline(element) {
    let computedStyle = getComputedStyle(element);
    let originalMargin = parseInt(computedStyle.getPropertyValue("margin-bottom"));

    function _fix() {
        // Get actual size of 1 rem in px
        const remSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
        const elemHeight = element.getBoundingClientRect().height;
        const elemHeightInRem = elemHeight / remSize;

        // Fix images using setting their height to a direct multiple of 1rem
        if (element instanceof HTMLImageElement) {
            const heightDecimalPart = elemHeightInRem % 1;
            const marginInRem = heightDecimalPart > 0.5 ? 1 - heightDecimalPart : heightDecimalPart * -1;
            element.style.marginBottom = (marginInRem + (originalMargin / remSize)) + 'rem';
        }
    }

    // Wait for image to load.
    if (element instanceof HTMLImageElement) {
        element.complete ? _fix() : element.onload = _fix;
    } else {
        _fix();
    }

    window.addEventListener("resize", debounce(()=>{
     _fix();
     }, 600))

}

// example usage
for (let elem of document.getElementsByClassName('js-fix-baseline')) {
    fixBaseline(elem);
}

let toggleGrid = document.querySelector(".button-toggle-grid");
if(toggleGrid !== null){
    toggleGrid.addEventListener("click", ()=> {
        document.body.classList.toggle("with-grid");
    });
}
